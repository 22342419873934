#DataEntryTable {
  border-color: black;
}
#DataEntryHeading {
  color: rgb(0, 8, 8);
  text-align: left;
}

th {
  border-style: solid;
  text-align: center;
  color: black;
  width: 5rem;
}

td {
  width:5rem;
}

input {
  width: 100px;
  margin: 5px;
  text-align: right;
  background-color:rgba(240, 255, 255, 0.796);
}

/*
.inputForm {
  width: 67%;
  margin: 5px;
}
*/

label {
  text-align: left;
  color: rgb(0, 14, 14);
}

.BookListTable {
  background-color: rgb(165, 202, 235);
  border-style: solid;
}

.AddBookButton {
  color: black;
  font-weight: bold;
  background-color: rgb(0, 81, 255);
}

.DeleteBookButton {
  color:black;
  font-weight: bold;
  background-color:red;
}

.DataManager {
  background-color: rgb(63, 113, 205);
  color: azure;
}

.DataForm {
  color: white;
}
