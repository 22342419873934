.MoviePage {
  display: grid;
  justify-content: center;
}

.MovieList {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 5px;
  padding: 0px;
}

.ZMovieList {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  padding: 0px;
}

.SinglePhoto {
  height: 700px;
  width: 400px;
}

.Photo {
  height: 350px;
  
}

.xHomeCards {
  height: 350px;
  padding: 2.5px;
  margin: 1px;
  gap: 1px;
}
.HomeCards:hover {
  transform: scale(1.05);
}

.CardBody {
  background-color: #282c34;
  height: 150px;
  padding-bottom: 5px;
  color: aliceblue;
  font-size: medium;
}
.MovieScreen {
  width: 35rem;
  padding: 0px;
  margin: 0;
  gap: 0;
  color: white;
  }

.CardLinks {
  text-align: center;
  font-size: medium;
}

@media screen and (max-width: 600px) {
  .MovieList {
    grid-template-columns: 1fr 1fr;
  }
}

.search {
  border: none;
  outline: none;
  padding: 5px;
  width: 250px;
  line-height: 20px;
  margin-bottom: 5px;
  background-color:lightgrey;
}

.flex-container {
  display: flex;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
}