body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.footer {
  color: aliceblue;
  background-color:midnightblue;
  
  text-align: center;
}

.header {
  color: aliceblue;
  background-color:midnightblue;
  
  text-align: center;
}

button {
  width: 100px;
  cursor: pointer;
  background-color: cornflowerblue;
}
#DataEntryTable {
  border-color: black;
}
#DataEntryHeading {
  color: rgb(0, 8, 8);
  text-align: left;
}

th {
  border-style: solid;
  text-align: center;
  color: black;
  width: 5rem;
}

td {
  width:5rem;
}

input {
  width: 100px;
  margin: 5px;
  text-align: right;
  background-color:rgba(240, 255, 255, 0.796);
}

/*
.inputForm {
  width: 67%;
  margin: 5px;
}
*/

label {
  text-align: left;
  color: rgb(0, 14, 14);
}

.BookListTable {
  background-color: rgb(165, 202, 235);
  border-style: solid;
}

.AddBookButton {
  color: black;
  font-weight: bold;
  background-color: rgb(0, 81, 255);
}

.DeleteBookButton {
  color:black;
  font-weight: bold;
  background-color:red;
}

.DataManager {
  background-color: rgb(63, 113, 205);
  color: azure;
}

.DataForm {
  color: white;
}

.MoviePage {
  display: grid;
  justify-content: center;
}

.MovieList {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px;
  gap: 5px;
  padding: 0px;
}

.ZMovieList {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  gap: 5px;
  padding: 0px;
}

.SinglePhoto {
  height: 700px;
  width: 400px;
}

.Photo {
  height: 350px;
  
}

.xHomeCards {
  height: 350px;
  padding: 2.5px;
  margin: 1px;
  grid-gap: 1px;
  gap: 1px;
}
.HomeCards:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.CardBody {
  background-color: #282c34;
  height: 150px;
  padding-bottom: 5px;
  color: aliceblue;
  font-size: medium;
}
.MovieScreen {
  width: 35rem;
  padding: 0px;
  margin: 0;
  grid-gap: 0;
  gap: 0;
  color: white;
  }

.CardLinks {
  text-align: center;
  font-size: medium;
}

@media screen and (max-width: 600px) {
  .MovieList {
    grid-template-columns: 1fr 1fr;
  }
}

.search {
  border: none;
  outline: none;
  padding: 5px;
  width: 250px;
  line-height: 20px;
  margin-bottom: 5px;
  background-color:lightgrey;
}

.flex-container {
  display: flex;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
}
